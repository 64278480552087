import shopApi from "../../api/shop";
import { QUICKVIEW_CLOSE, QUICKVIEW_OPEN } from "./quickviewActionTypes";

let cancelPreviousRequest = () => {};

export function quickviewOpenSuccess(product) {
  return {
    type: QUICKVIEW_OPEN,
    product,
  };
}

export function quickviewClose() {
  return {
    type: QUICKVIEW_CLOSE,
  };
}

export function quickviewOpen(product) {
  return (dispatch) => {
    cancelPreviousRequest();
    return new Promise((resolve) => {
      setTimeout(() => {
        dispatch(quickviewOpenSuccess(product));
        resolve();
      }, 350);
    });
  };
}
