// react
import React from 'react';



function ProductTabSpecification(props) {
    const {product} = props;
    const sections = product.specs.map((section, index) => {
        const features = section.value !== ""? (
            <div key={index} className="spec__row">
                <div className="spec__name">{section.label}</div>
                <div className="spec__value">{section.value}</div>
            </div>
        ): (<></>);

        return (
            <div key={index} className="spec__section">
                {features}
            </div>
        );
    });

    return (
        <div className="spec">
            <h3 className="spec__header">Especificaciones</h3>
            {sections}
            <div key={"sjkdhskj"} className="spec__row">
                  <div className="spec__name">{"Descripción"}</div>
                  <div className="spec__value">{product.description}</div>
            </div>
            
        </div>
    );
}

export default ProductTabSpecification;
